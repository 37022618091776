<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      "Referral Link": "Link de Indicação",
      "Username is required": "Usuário é obrigatório",
      Update: "Atualizar",

      "Referral link updated successfully!":
        "Link de indicação atualizado com sucesso!",
      "Referral link already belongs to another user!":
        "O link de indicação já pertence a outro usuário!",
      "This is already your current link, choose another one!":
        "Esse já é seu link atual, escolha outro!",
    },
    es: {
      "Referral Link": "Link de Patrocineo",
      "Username is required": "Nombre de usuario es requerido",
      Update: "Atualizar",

      "Referral Link updated successfully!":
        "Link de patrocineo actualizado correctamente!",
      "Referral Link already belongs to another user!":
        "¡El enlace de referencia ya pertenece a otro usuario!",
      "This is already your current link, choose another one!":
        "Este ya es tu enlace actual, ¡elige otro!",
    },
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  data() {
    return {
      indication: this.$store.state.account.user.indication,
      loading: false,

      alert: {
        type: "",
        message: "",
      },
    };
  },
  validations: {
    indication: { required },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.indication) {
          api
            .post("user/indication", {
              indication: this.indication,
            })
            .then((response) => {
              if (response.data.status == "success") {
                this.loading = false;
                this.alert.type = "alert-success";
                this.alert.message = response.data.message;

                this.$store.state.account.user.indication = this.indication;

                var user = JSON.parse(localStorage.getItem("user"));
                user.indication = this.indication;
                localStorage.setItem("user", JSON.stringify(user));
              } else {
                this.loading = false;
                this.alert.type = "alert-danger";
                this.alert.message = response.data.message;
              }
            });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Referral Link") }}</h4>
        </div>
        <div v-if="alert.message" :class="'alert ' + alert.type">
          {{ t(alert.message) }}
        </div>
        <div class="card">
          <div class="card-body">
            <h6>Personalize o seu link de indicação</h6>
            <b-form @submit.prevent="handleSubmit" inline>
              <h4 class="mb-0 font-size-16">
                https://app.notaquerende.org.br/
              </h4>
              <b-form-input
                v-model="indication"
                class="font-size-16"
                :class="{ 'is-invalid': $v.indication.$error }"
                v-mask="'XXXXXXXXXXXXXXXXXXXXXXXXXXXXX'"
              >
              </b-form-input>
              <b-button
                type="submit"
                :disabled="indication && loading == true"
                variant="default"
                class="ml-3 btn-update"
              >
                {{ t("Update") }}
                <b-spinner
                  v-if="loading"
                  small
                  class="ml-2 align-middle"
                  variant="white"
                  role="status"
                ></b-spinner>
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
@media (max-width: 676px) {
  .btn-update {
    margin-top: 5px;
    margin-left: 0 !important;
    width: 100%;
  }
}
</style>
